import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

function PercentageCalculationWidget(props) {
  return (
    <>
      <Form.Field style={{ marginBottom: '15px' }}>
        <label
          className="control-label"
          style={{ display: 'block' }}
          htmlFor={props.id + '_numerator'}
        >
          {props.schema.numerator}
          {props.required ? <span class="required">*</span> : <></>}
        </label>
        <Input
          type="number"
          value={props.value?.numerator}
          name="numerator"
          id={props.id + '_numerator'}
          onChange={(e, data) => {
            var valToSave = { ...props.value };
            valToSave.numerator = data.value;
            valToSave.result = calculatePercentage(
              valToSave.numerator,
              valToSave.denominator
            );
            if (props.onChange) {
              props.onChange(valToSave);
            }
          }}
          required={props.required}
          disabled={props.readonly || props.disabled}
        />
      </Form.Field>
      <Form.Field style={{ marginBottom: '15px' }}>
        <label
          className="control-label"
          style={{ display: 'block' }}
          htmlFor={props.id + '_denominator'}
        >
          {props.schema.denominator}
          {props.required ? <span class="required">*</span> : <></>}
        </label>
        <Input
          type="number"
          value={props.value?.denominator}
          name="denominator"
          id={props.id + '_denominator'}
          onChange={(e, data) => {
            var valToSave = { ...props.value };
            valToSave.denominator = data.value;
            valToSave.result = calculatePercentage(
              valToSave.numerator,
              valToSave.denominator
            );
            if (props.onChange) {
              props.onChange(valToSave);
            }
          }}
          required={props.required}
          disabled={props.readonly || props.disabled}
        />
      </Form.Field>
      <Form.Field style={{ marginBottom: '15px' }}>
        <label className="control-label">{props.schema.result}</label>
        <span style={{ paddingLeft: '5px' }} id={props.id + '_result'}>
          {props.value?.result}
        </span>
      </Form.Field>
    </>
  );

  function calculatePercentage(numerator, denominator) {
    if (
      !numerator ||
      !denominator ||
      denominator === 0 ||
      denominator === '0'
    ) {
      return ''; // Handle division by zero
    }
    let percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2) + '%'; // Return result rounded to 2 decimal places
  }
}

PercentageCalculationWidget.defaultProps = {};

export default PercentageCalculationWidget;
