import DOMPurify from 'dompurify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { CAPA } from './CAPA/CAPA';
import { PercentageCalculation } from './PercentageCalculation/PercentageCalculation';
import {
  CardDefaultParameterInputs,
  MultipleChoice,
} from './CheckboxList/CheckboxList';
import { SingleChoice } from './RadioList/RadioList';
import { CitationPicker } from './CitationPicker/CitationPicker';
import { ResponsiveScript } from './ResponsiveScript/ResponsiveScript';
import { TQAnswer, TQLabel } from './TQAnswer/TQAnswer';

//import utils
import AmsCitationDropdown from '../../../utils/AmsCitationDropdown';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import SurveyLookupDropdown from '../../../utils/SurveyLookupDropdown';
import Wysiwyg from '../../Shared/Wysiwyg';

//import actions
import { formBuilderFocusedComponent } from '../../../actions/surveyActions';
import SurveyQuestionLookups from '../../../utils/SurveyQuestionLookups';
import { withSurveyCascadingDropdowns } from '../../../utils/withSurveyCascadingDropdowns';
import { GrantsList } from './GrantsList/GrantsList';

const CascadingDropdowns = withSurveyCascadingDropdowns(SurveyQuestionLookups);

function AccordionTitleBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Accordion Title" fieldLabel="filterAccordionTitle">
        <AmsLookupDropdown
          placeholder="Select Accordion Title"
          fluid
          search
          selection
          value={parameters.value}
          name="accordionTitle"
          control={AmsLookupDropdown}
          category={'accordionTitle'}
          onChange={(ev, { name, value }) =>
            onChange({ ...parameters, value: value })
          }
          aria-labelledby="filterAccordionTitle"
          searchInput={{
            id: 'filterAccordionTitle',
            title: 'Filter by Accordion Title',
          }}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function ReviewTypeBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Review Type" fieldLabel="filterReviewType">
        <AmsLookupDropdown
          placeholder="Select Review Type"
          fluid
          search
          disabled={parameters['ui:options'].disabled ?? false}
          selection
          value={parameters.value}
          name="reviewType"
          control={AmsLookupDropdown}
          category={'easReviewTypes'}
          onChange={(ev, { name, value }) =>
            onChange({ ...parameters, value: value })
          }
          aria-labelledby="filterReviewType"
          searchInput={{
            id: 'filterReviewType',
            title: 'Filter by Review Type',
          }}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function ContentAreaBody({ parameters, onChange }) {
  if (parameters.hide) {
    return <></>;
  }
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Content Area" fieldLabel="filterContentArea">
        <CascadingDropdowns
          placeholder="Select Content Area"
          fluid
          search
          selection
          disabled={
            parameters.value == null
              ? !ValidateDisableField(parameters, ['reviewType', 'fiscalYear'])
              : false
          }
          value={parameters.value}
          name="contentArea"
          control={CascadingDropdowns}
          onChange={onChange}
          aria-labelledby="filterContentArea"
          searchInput={{
            id: 'filterContentArea',
            title: 'Filter by Content Area',
          }}
          target={parameters}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function ProgramTypeBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Program Type" fieldLabel="filterProgramType">
        <AmsLookupDropdown
          placeholder="Select Program Type"
          fluid
          search
          selection
          value={parameters.value}
          name="programType"
          control={AmsLookupDropdown}
          category={'easProgramType'}
          onChange={(ev, { name, value }) =>
            onChange({ ...parameters, value: value })
          }
          aria-labelledby="filterProgramType"
          searchInput={{
            id: 'filterProgramType',
            title: 'Filter by Program Type',
          }}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function ProgramOptionBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Program Option" fieldLabel="filterProgramOption">
        <AmsLookupDropdown
          placeholder="Select Program Option"
          fluid
          search
          selection
          multiple={true}
          value={parameters.value || []}
          name="programOptions"
          control={AmsLookupDropdown}
          category={'easProgramOption'}
          onChange={(ev, { name, value }) =>
            onChange({ ...parameters, value: value })
          }
          aria-labelledby="filterProgramOption"
          searchInput={{
            id: 'filterProgramOption',
            title: 'Filter by Program Option',
          }}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function FindingCategoryBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Finding Category" fieldLabel="filterFindingCategory">
        <SurveyLookupDropdown
          placeholder="Select Finding Category"
          fluid
          search
          selection
          value={parameters.value}
          name="findingCategory"
          control={AmsLookupDropdown}
          category={'findingCategory'}
          onChange={(ev, { name, value }) =>
            onChange({ ...parameters, value: value })
          }
          aria-labelledby="filterFindingCategory"
          searchInput={{
            id: 'filterFindingCategory',
            title: 'Filter by Finding Category',
          }}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function PerformanceAreaBody({ parameters, onChange }) {
  if (parameters.hide) {
    return <></>;
  }
  return (
    <Form.Field width={3}>
      <AmsFormLabel name="Performance Area" fieldLabel="filterPerformanceArea">
        <CascadingDropdowns
          placeholder="Select Performance Area"
          fluid
          search
          selection
          disabled={
            parameters.value == null
              ? !ValidateDisableField(parameters, [
                  'reviewType',
                  'fiscalYear',
                  'contentArea',
                ])
              : false
          }
          control={CascadingDropdowns}
          value={parameters.value}
          name="performanceArea"
          onChange={onChange}
          aria-labelledby="filterPerformanceArea"
          searchInput={{
            id: 'filterPerformanceArea',
            title: 'Filter by Performance Area',
          }}
          target={parameters}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function PerformanceMeasureBody({ parameters, onChange }) {
  return (
    <Form.Field width={3}>
      <AmsFormLabel
        name="Performance Measure"
        fieldLabel="filterPerformanceMeasure"
      >
        <CascadingDropdowns
          placeholder="Select Performance Measure"
          fluid
          search
          selection
          disabled={
            parameters.value == null
              ? !ValidateDisableField(parameters, [
                  'reviewType',
                  'fiscalYear',
                  'contentArea',
                  'performanceArea',
                ])
              : false
          }
          control={CascadingDropdowns}
          value={parameters.value}
          name="performanceMeasure"
          onChange={onChange}
          aria-labelledby="filterPerformanceMeasure"
          searchInput={{
            id: 'filterPerformanceMeasure',
            title: 'Filter by Performance Measure',
          }}
          id="form-builder-performanceMeasure"
          target={parameters}
        />
      </AmsFormLabel>
    </Form.Field>
  );
}

function ValidateDisableField(parameters, items) {
  const parameterKeys = Object.keys(parameters).filter(
    key => parameters[key] != null
  );
  return items.every(item => parameterKeys.includes(item));
}

function EvidenceSourceBody({ parameters, onChange }) {
  if (parameters.hide) {
    return <></>;
  }
  return (
    <div className="ec-form-container">
      <Form.Field width={3}>
        <AmsFormLabel name="Guide" fieldLabel="filterEvidenceSource">
          <SurveyLookupDropdown
            placeholder="Select Guide"
            fluid
            search
            selection
            multiple={false}
            value={parameters.value}
            name="evidenceSource"
            control={SurveyLookupDropdown}
            category={'easEvidenceSource'}
            onChange={(ev, { name, value }) => {
              onChange({ ...parameters, value: value });
            }}
            aria-labelledby="filterEvidenceSource"
            searchInput={{
              id: 'filterEvidenceSource',
              title: 'Filter by Guide',
            }}
            target={parameters.reviewType}
          />
        </AmsFormLabel>
      </Form.Field>
    </div>
  );
}

function TagsBody({ parameters, onChange }) {
  return (
    <div>
      <div className="tags-label">
        <label>Tags:</label>
      </div>
      <input
        type="text"
        className="form-control tags-input"
        onChange={ev => onChange({ ...parameters, value: ev.target.value })}
        name={parameters.name}
        value={parameters.value}
      />
      <div className="help-text">
        Tags should always be comma-separated. Spaces will be trimmer from the
        front and end of tags.
      </div>
    </div>
  );
}

function OrderBody({ parameters, onChange }) {
  if (parameters.hide) {
    return <></>;
  }
  return (
    <div>
      <label>Order:</label>
      <input
        type="number"
        placeholder="1"
        min="0"
        className="form-control order-input"
        // accept only numbers
        onKeyDown={function(event) {
          if (event.key == '.' || event.key == '-' || event.key == '+')
            event.preventDefault();
        }}
        name={parameters.name}
        value={parameters.value}
        onChange={ev => onChange({ ...parameters, value: ev.target.value })}
      />
    </div>
  );
}

function ReferenceNotesBody({ parameters, onChange }) {
  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);

  const isFocused = focusedComponent === 'ReferenceNotes';

  const handleFocus = () => {
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('ReferenceNotes'));
    }, 250);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className="focusable-wsywig-form-input"
    >
      <label>Reference Notes:</label>
      <div>
        {isFocused === false && (
          <div
            className="form-control reference-notes-input wsywig-input-unfocused"
            name={parameters.name}
            value={parameters.value}
            onFocus={handleFocus}
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parameters.value),
            }}
          />
        )}
        {isFocused === true && (
          <Wysiwyg
            id={'reference-notes-trix-editor'}
            focusOnInit={true}
            value={parameters.value}
            onChange={content => {
              onChange({ ...parameters, value: content });
            }}
          />
        )}
      </div>
    </div>
  );
}

function ScriptBody({ parameters, onChange }) {
  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);

  const isFocused = focusedComponent === 'ScriptBody';

  const handleFocus = () => {
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('ScriptBody'));
    }, 250);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className="focusable-wsywig-form-input"
    >
      <label>Reviewer Script:</label>
      <div>
        {isFocused === false && (
          <div
            className="form-control script-input wsywig-input-unfocused"
            name={parameters.name}
            value={parameters.value}
            onFocus={handleFocus}
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parameters.value),
            }}
          />
        )}
        {isFocused === true && (
          <Wysiwyg
            id={'script-trix-editor'}
            focusOnInit={true}
            value={parameters.value}
            onChange={content => {
              onChange({ ...parameters, value: content });
            }}
          />
        )}
      </div>
    </div>
  );
}

function GuidanceBody({ parameters, onChange }) {
  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);
  const isFocused = focusedComponent === 'GuidanceBody';

  const handleFocus = () => {
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('GuidanceBody'));
    }, 250);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className="focusable-wsywig-form-input"
    >
      <div>
        <label>Guidance:</label>
      </div>
      <div>
        {isFocused === false && (
          <div
            className="form-control guidance-input wsywig-input-unfocused"
            name={parameters.name}
            value={parameters.value}
            onFocus={handleFocus}
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parameters.value),
            }}
          />
        )}
        {isFocused === true && (
          <Wysiwyg
            value={parameters.value}
            focusOnInit={true}
            id={'guidance-trix-editor'}
            onChange={content => {
              onChange({ ...parameters, value: content });
            }}
          />
        )}
      </div>
    </div>
  );
}

function CitationsBody({ parameters, onChange }) {
  return (
    <Form.Field>
      <AmsCitationDropdown
        name="citations"
        value={parameters.value || []}
        multiple
        selection
        search
        onValueSelected={value => {
          onChange({ ...parameters, value: value });
        }}
        onError={error => console.log(error)}
      />
    </Form.Field>
  );
}

function FiscalYearBody({ parameters, onChange }) {
  return <></>;
}

export const customFormInputs = {
  fileUpload: {
    displayName: 'File Upload',
    matchIf: [
      {
        types: ['string'],
        field: 'attachmentField',
        format: 'data-url',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      format: 'data-url',
    },
    defaultUiSchema: {
      'ui:field': 'attachmentField',
    },
    type: 'string',
    cardBody: (parameters, onChange) => (
      <div>
        <h5>Upload File</h5>
        <input
          value={parameters.default}
          placeholder="Default"
          type="file"
          onChange={ev => onChange({ ...parameters, default: ev.target.value })}
        />
      </div>
    ),
    modalBody: (parameters, onChange) => <div>Adding File Component</div>,
  },
  multiFileUpload: {
    displayName: 'Multi File Upload',
    matchIf: [
      {
        types: ['array'],
        format: 'multiFileUpload',
      },
    ],
    defaultDataSchema: {
      type: 'array',
      format: 'multiFileUpload',
      items: {
        type: 'string',
      },
    },
    defaultUiSchema: {
      items: { 'ui:field': 'attachmentField' },
      'ui:options': {
        orderable: false,
        removable: true,
      },
    },
    type: 'array',
    cardBody: (parameters, onChange) => (
      <div>
        <h5>Upload Multiple Files</h5>
        <input
          value={parameters.default}
          placeholder="Default"
          type="file"
          onChange={ev => onChange({ ...parameters, default: ev.target.value })}
        />
      </div>
    ),
    modalBody: (parameters, onChange) => <div>Adding File Component</div>,
  },
  checkboxList: {
    displayName: 'Checkbox List',
    matchIf: [
      {
        types: ['string', 'number', 'integer', 'array', 'boolean', 'null'],
        widget: 'checkboxes',
      },
    ],
    defaultDataSchema: {
      type: 'array',
      title: 'A multiple-choice list',
      items: {
        type: 'string',
        enum: [],
        enumNames: [],
      },
      uniqueItems: true,
      minItems: 1,
      hasOutcome: 0,
      showOther: false,
    },
    defaultUiSchema: {
      'ui:widget': 'checkboxes',
    },
    type: 'array',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  eas_radio_list: {
    displayName: 'EAS Radio List',
    matchIf: [
      {
        types: ['string', 'number', 'integer', 'array', 'boolean', 'null'],
        widget: 'eas_radio_list',
      },
    ],
    defaultDataSchema: {
      type: 'array',
      title: 'A single-choice list',
      items: {
        type: 'string',
        enum: [],
        enumNames: [],
      },
      uniqueItems: true,
      minItems: 1,
      hasOutcome: 0,
      showOther: false,
    },
    defaultUiSchema: {
      'ui:widget': 'eas_radio_list',
    },
    type: 'array',
    cardBody: SingleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  tq_answer: {
    displayName: 'TQ Question',
    matchIf: [
      {
        types: ['string'],
        widget: 'tq_answer',
        enum: true,
        format: 'radio-list',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      enum: ['Yes', 'No', 'NA'],
      enumNames: ['Yes', 'No', 'NA'],
      title: 'TQ #',
      description: 'TQ Question Text',
      reference_notes: '',
      guidance: '',
      citations: [],
      format: 'radio-list',
    },
    defaultUiSchema: {
      'ui:widget': 'tq_answer',
    },
    type: 'string',
    cardBody: TQAnswer,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  responsive_script: {
    displayName: 'Responsive Script',
    matchIf: [
      {
        types: ['string', 'number', 'integer', 'array', 'boolean', 'null'],
        widget: 'responsive_script',
      },
    ],
    defaultDataSchema: {
      type: 'array',
      items: {
        type: 'string',
        enum: [],
        enumNames: [],
      },
      value: '',
      hasOutcome: 1,
    },
    defaultUiSchema: {
      'ui:widget': 'responsive_script',
    },
    cardBody: ResponsiveScript,
    modalBody: CardDefaultParameterInputs,
  },
  label_text: {
    displayName: 'Label',
    matchIf: [
      {
        types: ['string'],
        widget: 'label_text',
      },
    ],
    defaultDataSchema: {
      type: 'string',
    },
    defaultUiSchema: {
      'ui:widget': 'label_text',
      'ui:options': {
        readonly: true,
      },
    },
    type: 'string',
    cardBody: TQLabel,
    modalBody: CardDefaultParameterInputs,
  },
  wysiwyg: {
    displayName: 'Wysiwyg',
    matchIf: [
      {
        types: ['string'],
        field: 'wysiwyg',
      },
    ],
    defaultDataSchema: {
      type: 'string',
    },
    defaultUiSchema: {
      'ui:field': 'wysiwyg',
    },
    type: 'string',
  },
  accordionTitle: {
    displayName: 'Accordion Title',
    type: 'string',
    matchIf: [
      {
        widget: 'accordion_title',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'accordion_title',
      'ui:options': {
        label: false,
      },
    },
    cardBody: AccordionTitleBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  reviewType: {
    displayName: 'Review Type',
    type: 'string',
    matchIf: [
      {
        widget: 'review_type',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'review_type',
      'ui:options': {
        label: false,
      },
    },
    cardBody: ReviewTypeBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  fiscalYear: {
    displayName: 'Fiscal Year',
    type: 'string',
    matchIf: [
      {
        widget: 'fiscal_year',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'fiscal_year',
      'ui:options': {
        label: false,
      },
    },
    cardBody: FiscalYearBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  contentArea: {
    displayName: 'Content Area',
    type: 'string',
    matchIf: [
      {
        widget: 'content_area',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'content_area',
    },
    cardBody: ContentAreaBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  performanceArea: {
    displayName: 'Performance Area',
    type: 'string',
    matchIf: [
      {
        widget: 'performance_area',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'performance_area',
      'ui:options': {
        label: false,
      },
    },
    cardBody: PerformanceAreaBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  performanceMeasure: {
    displayName: 'Performance Measure',
    type: 'string',
    matchIf: [
      {
        widget: 'performance_measure',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'performance_measure',
      'ui:options': {
        label: false,
      },
    },
    cardBody: PerformanceMeasureBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  programType: {
    displayName: 'Program Type',
    type: 'string',
    matchIf: [
      {
        widget: 'program_type',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'program_type',
    },
    cardBody: ProgramTypeBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  programOption: {
    displayName: 'Program Option',
    type: 'string',
    matchIf: [
      {
        widget: 'program_option',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'program_option',
    },
    cardBody: ProgramOptionBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  findingCategory: {
    displayName: 'Finding Category',
    type: 'string',
    matchIf: [
      {
        widget: 'finding_category',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'finding_category',
    },
    cardBody: FindingCategoryBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  evidenceSource: {
    displayName: 'Guide',
    type: 'string',
    matchIf: [
      {
        widget: 'evidence_source',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'evidence_source',
      'ui:options': {
        label: false,
      },
    },
    cardBody: EvidenceSourceBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  tags: {
    displayName: 'Tags',
    type: 'string',
    matchIf: [
      {
        widget: 'tags',
        types: ['string', 'number', 'array', 'object'],
      },
    ],

    defaultUiSchema: {
      'ui:widget': 'tags',
      'ui:options': {
        label: false,
      },
    },
    cardBody: TagsBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  order: {
    displayName: 'Order',
    type: 'number',
    matchIf: [
      {
        widget: 'order',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'order',
      'ui:options': {
        label: false,
      },
    },
    cardBody: OrderBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  reference_notes: {
    displayName: 'Reference Notes',
    type: 'string',
    matchIf: [
      {
        widget: 'reference_notes',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'reference_notes',
      'ui:options': {
        label: false,
      },
    },
    cardBody: ReferenceNotesBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  script: {
    displayName: 'Script',
    type: 'string',
    matchIf: [
      {
        widget: 'script',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'script',
      'ui:options': {
        label: false,
      },
    },
    cardBody: ScriptBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  guidance: {
    displayName: 'Guidance',
    type: 'string',
    matchIf: [
      {
        widget: 'guidance',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'guidance',
      'ui:options': {
        label: false,
      },
    },
    cardBody: GuidanceBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  citations: {
    displayName: 'Citations',
    type: 'string',
    matchIf: [
      {
        widget: 'citations',
        types: ['string', 'number', 'array', 'object'],
      },
    ],
    defaultUiSchema: {
      'ui:widget': 'citations',
      'ui:options': {
        label: false,
      },
    },
    cardBody: CitationsBody,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  CAPA: {
    displayName: 'Content Area Performance Area',
    matchIf: [
      {
        types: ['string'],
        widget: 'CAPA',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      description: '',
    },
    defaultUiSchema: {
      'ui:widget': 'CAPA',
      'ui:options': {
        label: false,
      },
    },
    type: 'string',
    cardBody: CAPA,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  PercentageCalculation: {
    displayName: 'Percentage Calculation',
    matchIf: [
      {
        types: ['string'],
        widget: 'PercentageCalculation',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      description: '',
    },
    defaultUiSchema: {
      'ui:widget': 'PercentageCalculation',
      'ui:options': {
        label: false,
      },
    },
    type: 'string',
    cardBody: PercentageCalculation,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  GrantsList: {
    displayName: 'Grants List',
    matchIf: [
      {
        types: ['string'],
        widget: 'GrantsList',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      description: '',
    },
    defaultUiSchema: {
      'ui:widget': 'GrantsList',
      'ui:options': {
        label: false,
      },
    },
    type: 'string',
    cardBody: GrantsList,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
  CitationPicker: {
    displayName: 'Citation',
    matchIf: [
      {
        types: ['string'],
        widget: 'CitationPicker',
      },
    ],
    defaultDataSchema: {
      type: 'string',
      description: '',
    },
    defaultUiSchema: {
      'ui:widget': 'CitationPicker',
    },
    type: 'string',
    cardBody: CitationPicker,
    modalBody: (parameters, onChange) => <div>modalBody</div>,
  },
};
