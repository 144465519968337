import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import {
  Accordion,
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Icon,
  Input,
  Loader,
  Popup,
} from 'semantic-ui-react';
import AmsAlert from '../../../utils/AmsAlert';
import Tooltip from '../../Shared/Tooltip/Tooltip';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

//import actions
import { updateCollectedData } from '../../../actions/reviewActions';
import FindingsDownloadAttachement from './FindingsDownloadAttachement';
import {
  formatCheckBoxString,
  renderLabelWithCheckbox,
  renderDisabledLabelWithCheckbox,
} from './FindingsUtils';

const FindingsDataCollectionCard = props => {
  const { questionData, collectDataType, history, citation } = props;
  const { selectedReview } = useSelector(state => state.review);

  const [answerOptionsObj, setAnswerOptionsObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };
  const [
    showDeleteConfirmationAlert,
    setShowDeleteConfirmationAlert,
  ] = useState(false);
  const [removedQuestionOption, setRemovedQuestionOption] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState('');
  const [answerOptionsOriginalObj, setAnswerOptionsOriginalObj] = useState({});
  //FY25 FA1-FR, FA2-CR, Special, and Follow up reviews
  const newCollectedData =
    selectedReview.fiscalYear > 2024 &&
    ['FA1-FR', 'FA2-CR', 'Special', 'Follow-up'].includes(
      selectedReview.reviewType
    );

  const dispatch = useDispatch();

  useEffect(() => {
    let toReturn = {};
    questionData.forEach(question => {
      toReturn[question.questionId] = question.collectedOptions
        .filter(op => op.isActive)
        .map(option => {
          if (option.custom) {
            return { ...option, edit: false, oldLabel: option.label };
          } else {
            return { ...option };
          }
        });
    });

    setAnswerOptionsObj(toReturn);
    setAnswerOptionsOriginalObj(cloneDeep(toReturn));
  }, [questionData]);

  const updateAnswers = questionId => {
    setLoading(true);

    const input = {
      findingId: props.findingId,
      questionId: questionId,
      collectedOptions: answerOptionsObj[questionId].map(e => {
        const objToReturn = {
          label: e.label,
          checked: e.checked,
          custom: e.custom,
          isActive: e.isActive,
          altText: e.altText,
        };

        if (e.oldLabel !== e.label) {
          objToReturn.oldLabel = e.oldLabel;
        }
        return objToReturn;
      }),
    };
    dispatch(
      updateCollectedData(
        input,
        { reviewId: selectedReview.reviewId },
        { reviewType: selectedReview.reviewType },
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(data => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (questionId, optionIndex) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].checked = !newAnswerOptionsObj[
      questionId
    ][optionIndex].checked;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const handleCustomInputChange = (questionId, optionIndex, event) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].label = event.target.value;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const handleEditButtonClick = (questionId, optionIndex) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].edit = !newAnswerOptionsObj[
      questionId
    ][optionIndex].edit;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const handleDeleteButtonClick = (questionId, optionIndex) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].isActive = false;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const showDeleteConfirmation = () => {
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title={'Are you sure you want to delete this option?'}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete'}
        cancelButtonText={'No, cancel'}
        onCancel={() => {
          setShowDeleteConfirmationAlert(false);
        }}
        onConfirm={() => {
          setShowDeleteConfirmationAlert(false);
          handleDeleteButtonClick(
            removedQuestionOption.questionId,
            removedQuestionOption.index
          );
        }}
      />
    );
  };

  const renderAnswerHistory = () => {
    return (
      <ul className="eas-ul">
        {history.map(
          (entry, index) =>
            !isEmpty(entry.actions) &&
            ((entry.actions.length === 1 &&
              !isEmpty(entry.actions[0]?.answerOption)) ||
              entry.actions.length > 1) && (
              <li className="eas-li history-item" key={index}>
                <span className="line"></span>
                <span className="number"></span>
                <div className="blob">
                  <span className="bold">{entry.questionCode}</span> answers on
                  Guide: <span className="bold">{entry.evidenceSource}</span>,
                  Performance Area:{' '}
                  <span className="bold">{entry.performanceArea}</span> changed{' '}
                  {entry.source} by <span className="bold">{entry.who}</span> on{' '}
                  <span className="bold">
                    {AmsDateFormatters.getMoment(entry.updatedTime).format(
                      'M/D/YYYY, h:mm A'
                    )}
                  </span>
                  <ul>
                    {entry.actions.map(
                      (action, actionIndex) =>
                        !isEmpty(action.answerOption) && (
                          <li key={actionIndex}>
                            <span className="bold">
                              {action.actionType.charAt(0).toUpperCase() +
                                action.actionType.slice(1) +
                                ' '}
                            </span>
                            {action.actionType === 'updated' ? (
                              action.custom ? (
                                <span>
                                  to "{action.answerOption}" from "
                                  {action.oldAnswerOption}" (custom)
                                </span>
                              ) : (
                                <span>{action.answerOption}</span>
                              )
                            ) : (
                              <span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      formatCheckBoxString(action.answerOption)
                                    ),
                                  }}
                                />{' '}
                                {action.custom ? ` (custom)` : ''}
                              </span>
                            )}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </li>
            )
        )}
      </ul>
    );
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={
          'Follow-up' === selectedReview.reviewType
            ? `Deselecting all answer options will cause a Parent review finding to be withdrawn or a New finding to be dropped from the report. Are you sure you want to continue?`
            : `Deselecting all answer options will cause the finding to be dropped from the report. Are you sure you want to continue?`
        }
        onConfirm={() => {
          setShowAlert(false);
          updateAnswers(activeQuestionId);
          props.handleSaveButtonAction('NO_CHECKBOX_CHECKED');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const getFilteredDataByKey = keyToRemove => {
    return Object.keys(answerOptionsOriginalObj).reduce((result, key) => {
      if (key !== keyToRemove) {
        result[key] = answerOptionsOriginalObj[key];
      }
      return result;
    }, {});
  };

  const answeredCheckboxes = question => {
    return (
      answerOptionsObj[question.questionId] &&
      Object.values(answerOptionsObj[question?.questionId]).map(
        (item, index) => {
          if (!item.isActive) {
            return null;
          }
          if (item.custom) {
            if (item.edit) {
              return (
                <Form.Group inline key={index} className="custom-checkbox">
                  <Checkbox
                    disabled={selectedReview.readOnly}
                    onChange={() =>
                      handleCheckboxChange(question.questionId, index)
                    }
                    checked={item.checked}
                  />
                  <Input
                    value={item.label}
                    size={'small'}
                    disabled={selectedReview.readOnly}
                    onChange={event =>
                      handleCustomInputChange(question.questionId, index, event)
                    }
                  />
                </Form.Group>
              );
            } else {
              return (
                <Form.Group inline key={index} className="custom-checkbox">
                  <Checkbox
                    disabled={selectedReview.readOnly}
                    onChange={() =>
                      handleCheckboxChange(question.questionId, index)
                    }
                    checked={item.checked}
                    label={item.label}
                  />
                  <Button.Group icon>
                    <Popup
                      trigger={
                        <Button
                          onClick={e => {
                            handleEditButtonClick(question.questionId, index);
                          }}
                          disabled={selectedReview.readOnly}
                          className="clickable"
                          style={{ background: 'none' }}
                        >
                          <i className="fa fa-pencil fa-md" />
                        </Button>
                      }
                      content={'Edit'}
                      size="mini"
                      inverted
                    />
                    &nbsp; | &nbsp;
                    <Popup
                      trigger={
                        <Button
                          onClick={e => {
                            setShowDeleteConfirmationAlert(true);
                            setRemovedQuestionOption({
                              questionId: question?.questionId,
                              index,
                            });
                          }}
                          disabled={selectedReview.readOnly}
                          className="clickable"
                          style={{ background: 'none' }}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      }
                      content={'Delete'}
                      size="mini"
                      inverted
                    />
                  </Button.Group>
                </Form.Group>
              );
            }
          } else {
            return (
              <Form.Group inline key={index} className="custom-checkbox">
                <Checkbox
                  key={index}
                  disabled={selectedReview.readOnly}
                  label={renderLabelWithCheckbox(
                    item,
                    handleCheckboxChange,
                    question.questionId,
                    index,
                    selectedReview.readOnly
                  )}
                  checked={item.checked}
                />
              </Form.Group>
            );
          }
        }
      )
    );
  };

  const answeredCheckboxesWithAlt = question => {
    let showCustomInputs = true;
    let prevItem = null;
    let prevIndex = -1;
    let options = [];
    if (answerOptionsObj[question.questionId])
      options = Object.values(answerOptionsObj[question?.questionId]);
    var notCutomOptions = options.filter(x => !x.custom);
    var lastOption = notCutomOptions[notCutomOptions.length - 1];

    return options.map((item, index) => {
      if (!item.isActive) {
        return null;
      }
      let prevAltText = <></>;
      if (!/^[0-9]_/.test(item.label)) {
        if (prevItem && !prevItem.custom && prevItem.altText) {
          prevAltText = altTextCheckBox(question, prevItem, prevIndex);
        }
        prevItem = item;
        prevIndex = index;
      }
      if (item.custom) {
        var header = <></>;
        if (showCustomInputs) {
          showCustomInputs = false;
          header = (
            <label style={{ fontFamily: 'PublicSans-Bold', marginTop: '15px' }}>
              Added Report Options
            </label>
          );
        }
        if (item.edit) {
          return (
            <>
              {header}
              <Form.Group
                inline
                key={index}
                className="custom-checkbox"
                style={{ marginLeft: '20px' }}
              >
                <Checkbox
                  disabled={selectedReview.readOnly}
                  onChange={() =>
                    handleCheckboxChange(question.questionId, index)
                  }
                  checked={item.checked}
                />
                <Input
                  value={item.label}
                  size={'small'}
                  disabled={selectedReview.readOnly}
                  onChange={event =>
                    handleCustomInputChange(question.questionId, index, event)
                  }
                />
              </Form.Group>
            </>
          );
        } else {
          return (
            <>
              {header}
              <Form.Group
                inline
                key={index}
                className="custom-checkbox"
                style={{ marginLeft: '20px' }}
              >
                <Checkbox
                  disabled={selectedReview.readOnly}
                  onChange={() =>
                    handleCheckboxChange(question.questionId, index)
                  }
                  checked={item.checked}
                  label={item.label}
                />
                <Button.Group icon>
                  <Popup
                    trigger={
                      <Button
                        onClick={e => {
                          handleEditButtonClick(question.questionId, index);
                        }}
                        disabled={selectedReview.readOnly}
                        className="clickable"
                        style={{ background: 'none' }}
                      >
                        <i className="fa fa-pencil fa-md" />
                      </Button>
                    }
                    content={'Edit'}
                    size="mini"
                    inverted
                  />
                  &nbsp; | &nbsp;
                  <Popup
                    trigger={
                      <Button
                        onClick={e => {
                          setShowDeleteConfirmationAlert(true);
                          setRemovedQuestionOption({
                            questionId: question?.questionId,
                            index,
                          });
                        }}
                        disabled={selectedReview.readOnly}
                        className="clickable"
                        style={{ background: 'none' }}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    }
                    content={'Delete'}
                    size="mini"
                    inverted
                  />
                </Button.Group>
              </Form.Group>
            </>
          );
        }
      } else {
        return (
          <>
            {prevAltText}
            {item.altText || /^[0-9]_/.test(item.label) ? (
              <Form.Group inline key={index} className="custom-checkbox">
                <Checkbox
                  key={index}
                  disabled={true}
                  label={renderDisabledLabelWithCheckbox(item)}
                  checked={true}
                />
              </Form.Group>
            ) : (
              <Form.Group inline key={index} className="custom-checkbox">
                <Checkbox
                  key={index}
                  disabled={selectedReview.readOnly}
                  label={renderLabelWithCheckbox(
                    item,
                    handleCheckboxChange,
                    question.questionId,
                    index,
                    selectedReview.readOnly,
                    false
                  )}
                  checked={item.checked}
                />
              </Form.Group>
            )}
            {item == lastOption && prevItem.altText ? (
              altTextCheckBox(question, prevItem, prevIndex)
            ) : (
              <></>
            )}
          </>
        );
      }
    });
  };

  const altTextCheckBox = (question, prevItem, prevIndex) => {
    return (
      <Form.Group
        inline
        key={prevIndex}
        className="custom-checkbox"
        style={{ marginLeft: '20px' }}
      >
        <Checkbox
          key={prevIndex}
          disabled={selectedReview.readOnly}
          label={renderLabelWithCheckbox(
            prevItem,
            handleCheckboxChange,
            question.questionId,
            prevIndex,
            selectedReview.readOnly,
            true
          )}
          checked={prevItem.checked}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showDeleteConfirmation()}
      {showConfirmationAlert()}

      {questionData.map((question, index) => {
        const {
          evidenceSource,
          performanceArea,
          questionCode,
          shortDescription,
          questionText,
          narrative,
          contentArea,
          isActive,
        } = question;
        const dataCollectTypes = ['fotp', 'ersea'];
        return (
          <div style={{ margin: '20px', marginBottom: '-1px' }}>
            <Form
              className={
                isActive === false
                  ? 'disabled-finding-card'
                  : 'findings-collect-data-card'
              }
            >
              {/* <Form style={{ margin: '10px', marginBottom: '-40px' }}> */}
              {/* {index !== 0 && <Divider />} */}
              <Form.Field width={16}>
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>
                    {'fotp' === collectDataType ||
                    'New Findings' === citation.collectDataSubType
                      ? 'Content Area: '
                      : 'Guide: '}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    {dataCollectTypes.includes(collectDataType) ||
                    'New Findings' === citation.collectDataSubType
                      ? contentArea
                      : evidenceSource}
                  </span>
                </label>
              </Form.Field>
              <Form.Field width={16}>
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>
                    Performance Area:{' '}
                  </span>
                  <span style={{ fontWeight: 400 }}>{performanceArea}</span>
                </label>
              </Form.Field>

              {'New Findings' !== citation.collectDataSubType && (
                <Form.Field width={16}>
                  <label>{questionCode}</label>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        shortDescription || questionText
                      ),
                    }}
                  />
                </Form.Field>
              )}
              <Form.Field width={16}>
                <label style={{ fontFamily: 'PublicSans-Bold' }}>
                  {'fotp' === collectDataType
                    ? 'Explanation Provided'
                    : question?.tqAnswer === 'yes'
                    ? 'Selected Answer'
                    : 'Selected Answers'}
                  {newCollectedData ? (
                    <Tooltip
                      content="Unselect checkboxes to remove text from report"
                      position="top right"
                    ></Tooltip>
                  ) : (
                    <></>
                  )}
                </label>
                {(question?.tqAnswer === 'no' &&
                  'Follow-up' === selectedReview.reviewType &&
                  'Withdrawn' === citation?.followUpDetermination &&
                  'This finding has been withdrawn by the Office of Head Start.') ||
                  (question?.tqAnswer === 'yes' && 'Yes')}
                {('Follow-up' === selectedReview.reviewType ||
                  question?.tqAnswer === 'no') &&
                  !newCollectedData &&
                  answeredCheckboxes(question)}
                {question?.tqAnswer === 'no' &&
                  newCollectedData &&
                  answeredCheckboxesWithAlt(question)}
              </Form.Field>
              {('Follow-up' === selectedReview.reviewType ||
                question?.tqAnswer === 'no') && (
                <Form.Field width={16}>
                  <button
                    type="button"
                    disabled={selectedReview.readOnly}
                    className="add-option-link-button"
                    onClick={() => {
                      const updatedAnswerOptionsObj = { ...answerOptionsObj };
                      updatedAnswerOptionsObj[question.questionId].push({
                        label: '',
                        checked: true,
                        custom: true,
                        edit: true,
                        isActive: true,
                      });
                      setAnswerOptionsObj(updatedAnswerOptionsObj);
                    }}
                  >
                    {newCollectedData
                      ? 'Add report option'
                      : 'Add an answer option'}
                  </button>
                </Form.Field>
              )}
              {('Follow-up' === selectedReview.reviewType ||
                question?.tqAnswer === 'no') && (
                <Button
                  style={{
                    backgroundColor: '#245E9D',
                    color: 'white',
                    width: '250px',
                    marginTop: '10px',
                  }}
                  disabled={selectedReview.readOnly}
                  onClick={() => {
                    if (
                      answerOptionsObj?.[question.questionId].every(
                        option => !option.checked
                      ) &&
                      Object.values(
                        getFilteredDataByKey(question.questionId)
                      ).every(arr => arr.every(item => item.checked === false))
                    ) {
                      setShowAlert(true);
                      setActiveQuestionId(question.questionId);
                    } else {
                      updateAnswers(question.questionId);
                      props.handleSaveButtonAction('SAVE');
                    }
                  }}
                >
                  Save Answers
                </Button>
              )}
              <Form.Field width={16}>
                <div
                  style={{ fontFamily: 'PublicSans-Bold', marginTop: '20px' }}
                >
                  Notes
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(narrative),
                  }}
                />
              </Form.Field>
              <Form.Field width={16}>
                <span style={boldLabelStyle}>Evidence: </span>
                {question?.evidenceFiles?.length > 0 ? (
                  <FindingsDownloadAttachement
                    evidenceFiles={question?.evidenceFiles}
                    isLegacy={
                      citation.isLegacy &&
                      question.questionCode.startsWith('Question')
                    }
                  />
                ) : (
                  <div>No evidence is available</div>
                )}
              </Form.Field>
              <Divider />
            </Form>
          </div>
        );
      })}

      {history && history.length > 0 && (
        <div style={{ margin: '10px', textAlign: 'center' }}>
          <Accordion className={'enabled-section'}>
            <Accordion.Title
              active={showHistory}
              index={0}
              onClick={() => {
                setShowHistory(!showHistory);
              }}
            >
              {!showHistory ? 'See History' : 'Hide History'}
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={showHistory}>
              <div style={{ textAlign: 'left' }}>{renderAnswerHistory()}</div>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default FindingsDataCollectionCard;
