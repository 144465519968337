import { includes, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Button, List, Segment } from 'semantic-ui-react';

// Import component
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import ReviewCreateForm from '../Review/ReviewCreateForm';
import SummaryFilters from '../Shared/FilterHelpers/SummaryFilters';
import ReviewItems from './ReviewItems';

// Import Actions
import {
  filterGrantees,
  granteeFilterCleared,
} from '../../actions/granteeActions';
import { fetchFOMUsers } from '../../actions/userActions';

// Import style
import './assets/style.css';

import enforceRole from '../../utils/EnforceRole';

// Imort UI config
import { acl } from '../../config';

class ReviewPage extends Component {
  state = {
    granteeFilterQuery: '',
    loading: false,
    queryFilters: {},
    orderBy: 'dueDate',
    responseErrors: [],
    selectedFilters: {},
    currentUser: {},
    showFilters: true,

    filters: {},
    firstRun: true,
    errors: {},
  };

  constructor(props) {
    super(props);
    this.filterSegment = React.createRef();
  }

  componentDidMount() {
    // Get additional filters passed as query string.
    const { search } = this.props.location;

    let filterQueryString =
      search &&
      queryString.parse(search, {
        arrayFormat: 'bracket',
      });

    // Check if the user is LA and set the selectedReviewStatus to
    // empty instead of in progress.
    this.setSelectedReviewStatus();

    let fiscalYear;
    if (new Date().getMonth()+1 < 10) {
      fiscalYear = new Date().getYear() + 1900;
    } else {
      fiscalYear = new Date().getYear() + 1901;
    }
    fiscalYear = fiscalYear.toString();

    if (
      Object.keys(filterQueryString).length === 0 ||
      !filterQueryString.fiscalYear
    ) {
      filterQueryString = { ...filterQueryString, fiscalYear: [fiscalYear] };
    }
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filterQueryString,
        },
      },
      () => this.getData(this.state.filters)
    );

    this.props.fetchFOMUsers();
  }

  componentWillReceiveProps = nextProps => {
    this.setSelectedReviewStatus();
  };

  componentWillUnmount() {
    this.props.granteeFilterCleared();
  }

  setSelectedReviewStatus = () => {
    if (this.state.firstRun) {
      if (this.checkLAUserRole()) {
        this.setState({
          filters: { ...this.state.filters, selectedReviewStatus: [] },
          firstRun: false,
        });
      }
    }
  };

  checkLAUserRole = () => {
    const { currentUser } = this.props;

    let isLAUser = '';

    if (currentUser)
      isLAUser = includes(currentUser.roles, 'LogisticsAssociate');

    if (isLAUser) return true;

    return false;
  };

  getData = fil => {
    const { queryFilters } = this.state.filters;

    let filterInputs = {};

    if (!isEmpty(fil.reviewTypes)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        reviewTypes: fil.reviewTypes,
      };
    }

    if (!isEmpty(fil.selectedReviewStatus)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        reviewStatus: fil.selectedReviewStatus,
      };
    }

    if (!isEmpty(fil.selectedReviewOutcome)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        outcomeList: fil.selectedReviewOutcome,
      };
    }

    if (!isEmpty(fil.reviewId)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        reviewId: fil.reviewId,
      };
    }

    if (!isEmpty(fil.region)) {
      filterInputs.filters = { ...filterInputs.filters, region: fil.region };
    }

    if (!isEmpty(fil.selectedGrantees)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        granteeIdList: fil.selectedGrantees,
      };
    }

    if (!isEmpty(fil.fiscalYear)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        yearList: fil.fiscalYear.map(i => Number(i)),
      };
    }

    // If there are query stirng filters, add them in filters.
    if (!isEmpty(queryFilters)) {
      filterInputs.filters = { ...filterInputs.filters, ...queryFilters };
    }

    // Add From and To date filters.
    if (!isEmpty(fil.fromDate) && !isEmpty(fil.toDate)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        fromDate: AmsDateFormatters.getMoment(fil.fromDate).format(
          'YYYY-MM-DD'
        ),
        toDate: AmsDateFormatters.getMoment(fil.toDate).format('YYYY-MM-DD'),
      };
    }

    if (!isEmpty(fil.fomFilter)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        fomFilter: fil.fomFilter,
      };
    }

    //Check findings filter for a value
    if (fil.findings !== '') {
      filterInputs.filters = {
        ...filterInputs.filters,
        findings: fil.findings,
      };
    }

    if (fil.agencyId !== '') {
      filterInputs.filters = {
        ...filterInputs.filters,
        agencyId: fil.agencyId,
      };
    }

    if (!isEmpty(fil.selectedCitation)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        citations: fil.selectedCitation,
      };
    }

    if (!isEmpty(fil.selectedFindingTypes)) {
      filterInputs.filters = {
        ...filterInputs.filters,
        findingTypes: fil.selectedFindingTypes,
      };
    }

    if (fil.disallowance !== '') {
      filterInputs.filters = {
        ...filterInputs.filters,
        disallowance: fil.disallowance,
      };
    }

    // if (!isEmpty(filterInputs.filters))

    this.setState({ selectedFilters: filterInputs.filters }, () => {
      // const qs = queryString.stringify(this.state.filters, {
      //   arrayFormat: 'bracket',
      // });

      // this.context.router.replace(`/review?${qs}`);

      this.reviewItems.getData(this.state.selectedFilters);
    });
  };

  handleFilterReset = () => {
    let fiscalYear;
    if (new Date().getMonth() < 10) {
      fiscalYear = new Date().getYear() + 1900;
    } else {
      fiscalYear = new Date().getYear() + 1901;
    }
    fiscalYear = fiscalYear.toString();

    this.setState(
      {
        errors: {},
        responseErrors: [],
        granteeFilterQuery: '',
        loading: false,
      },
      () => {
        this.getData({ fiscalYear: [fiscalYear] });
        this.scrollToRef();
      }
    );
  };

  filterGrantees = () => {
    if (
      !this.state.granteeFilterQuery.searchQuery.trim() ||
      this.state.granteeFilterQuery.searchQuery.trim().length < 3
    )
      return;
    this.setState({ loading: true });

    const status = ['active', 'inactive'];

    this.props
      .filterGrantees(this.state.granteeFilterQuery.searchQuery.trim(), status)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({
          loading: false,
          responseErrors: [...this.state.responseErrors, error.response.data],
        });
      });
  };

  handleFilterValidation = filters => {
    const errors = {};
    if (!isEmpty(filters.fromDate) && !isEmpty(filters.toDate)) {
      if (filters.toDate.isBefore(filters.fromDate))
        errors.toDate = 'To date must be after From date';
      return errors;
    }

    if (!isEmpty(filters.fromDate) && isEmpty(filters.toDate))
      errors.toDate = 'Both From and To dates are required';
    if (isEmpty(filters.fromDate) && !isEmpty(filters.toDate))
      errors.fromDate = 'Both From and To dates are required';

    return errors;
  };

  filtersOnSubmit = filters => {
    const errors = this.handleFilterValidation(filters);

    this.setState({ errors });

    // No more errors submit data.
    if (Object.keys(errors).length === 0) {
      this.getData(filters);
    }
  };

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  renderReviewFilters() {
    const { errors } = this.state;

    const defaultFilters = [
      {
        key: 'reviewId',
        value: true,
      },
      {
        key: 'selectedGrantees',
        value: true,
      },
      {
        key: 'fiscalYear',
        value: true,
      },
    ];

    return (
      <div ref={this.filterSegment} tabIndex="-1">
        <Segment>
          {/* <div style={{ position: 'relative' }}> */}
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />
          <SummaryFilters
            page="Review"
            fiscalYear={this.state.filters.fiscalYear}
            errors={errors}
            defaultFilters={defaultFilters}
            data={{
              handleFilterSubmit: this.filtersOnSubmit,
              handleFilterReset: this.handleFilterReset,
            }}
            {...this.props}
          />
        </Segment>
      </div>
    );
  }

  renderReviewList() {
    const { selectedFilters } = this.state;
    return (
      <ReviewItems
        filters={selectedFilters}
        onFilter={ref => (this.reviewItems = ref)}
        onError={this.showReviewError.bind(this)}
      />
    );
  }

  showReviewError(error) {
    if (error && error.response && error.response.data)
      this.setState({
        responseErrors: [...this.state.responseErrors, error.response.data],
      });
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  renderCreateReviewForm() {
    const { currentUser } = this.props;

    return enforceRole(
      <div className="create-review-form-wrapper">
        <ReviewCreateForm />
      </div>,
      acl.actions.review.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  }

  handleButton = () => {
    this.context.router.push(`/review/create`);
  };

  toggleFilters = () => this.setState({ showFilters: !this.state.showFilters });

  render() {
    const { currentUser } = this.props;

    const actionButtons = (
      <>
        {/* <List.Item>
          <List.Content>
            <Button
              primary
              basic={!this.state.showFilters}
              icon="filter"
              size="large"
              onClick={this.toggleFilters}
              content={'Filters'}
            />
          </List.Content>
        </List.Item> */}
        <List.Item>
          <List.Content>
            {enforceRole(
              <Button
                content="Add Review"
                color="green"
                size="large"
                onClick={this.handleButton}
              />,
              acl.actions.review.create, // Allowed roles
              currentUser.roles // Current user roles
            )}
          </List.Content>
        </List.Item>
      </>
    );

    return (
      <>
        {/* <ScrollToTop /> */}
        {this.showResponseErrors()}

        <SlidingContainer
          calendar
          title={'Reviews'}
          actionButtons={actionButtons}
          // sidebarComponent={this.renderReviewFilters()}
        >
          {/* {
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-10" />
                <div
                  className="col-sm-2 text-right"
                  style={{ paddingBottom: 10 }}
                >
                  {enforceRole(
                    <Button
                      content="Add Review"
                      size="big"
                      color="green"
                      onClick={this.handleButton}
                    />,
                    acl.actions.review.create, // Allowed roles
                    currentUser.roles // Current user roles
                  )}
                </div>
              </div>
            </>
          } */}
          {this.renderReviewFilters()}
          {this.renderReviewList()}
        </SlidingContainer>

        {/* Render review children */}
        {this.props.children}
      </>
    );
  }
}

ReviewPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  granteesFiltered: PropTypes.array.isRequired,
  filterGrantees: PropTypes.func.isRequired,
  granteeFilterCleared: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  fomUsers: state.users.fomUsers,
  isAuthenticated: state.auth.isAuthenticated,
  lookups: state.lookups,
  granteesFiltered: state.granteeFilter,
});

export default connect(mapStateToProps, {
  filterGrantees,
  granteeFilterCleared,
  fetchFOMUsers,
})(ReviewPage);
