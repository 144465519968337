import * as React from 'react';
import CardEnumOptions from '../Shared/CardEnumOptions';
import CheckboxValidation from '../Shared/CheckboxValidation';

function getRandomId() {
  const chars = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const numberOfChars = chars.length;
  const randomIdLength = 50;

  return Array.from({ length: randomIdLength })
    .map(() => chars[Math.floor(Math.random() * numberOfChars)])
    .join('');
}

export function SingleChoice({ parameters, onChange }) {
  const enumArray = Array.isArray(parameters.items.enum)
    ? parameters.items.enum
    : [];
  const [elementId] = React.useState(getRandomId());

  // Handle changes in CardEnumOptions
  const handleCardEnumOptionsChange = (newEnum, newNames) => {
    // Update the schema
    onChange({
      ...parameters,
      items: {
        enum: newEnum, // updated enum values, already combined with altText if needed
        enumNames: newNames,
        type: 'string',
      },
    });
  };

  return (
    <div className="card-enum">
      <h3>Possible Values</h3>
      <CheckboxValidation
        onChangeValue={() => {
          onChange({
            ...parameters,
            showOther: !parameters.showOther,
          });
        }}
        isChecked={parameters.showOther}
        label="Add an other option with text(required)"
        id={`${elementId}_other`}
      />

      <CheckboxValidation
        onChangeValue={() => {
          onChange({
            ...parameters,
            hasOutcome: parameters.hasOutcome === 1 ? 0 : 1,
            items: {
              type: 'string',
              enum: parameters.items.enumNames,
              enumNames: parameters.items.enumNames,
            },
          });
        }}
        isChecked={parameters.hasOutcome === 1}
        label="Has an Outcome?"
        id={`${elementId}_outcome`}
      />

      <CardEnumOptions
        initialValues={enumArray}
        names={
          Array.isArray(parameters.items.enumNames)
            ? parameters.items.enumNames.map(val => `${val}`)
            : undefined
        }
        showNames={Array.isArray(parameters.items.enumNames)}
        showOther={parameters.showOther}
        hasOutcome={parameters.hasOutcome}
        hasAltText={parameters.enableAltText} // Whether there is an AltText or not for the Card Enum
        onChange={handleCardEnumOptionsChange} // Pass changes to handler
      />
    </div>
  );
}

// specify the inputs required for a string type object
export function CardDefaultParameterInputs({ parameters, onChange }) {
  return <div />;
}
