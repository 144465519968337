import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';
import React from 'react';
import AmsPopup from '../../../utils/AmsPopup';
import CAPAWidget from './CAPA/CAPAWidget';
import PercentageCalculationWidget from './PercentageCalculation/PercentageCalculationWidget';
import GrantsListWidget from './GrantsList/GrantsListWidget';
import CheckboxListWidgetPaths from './CheckboxList/CheckboxListWidgetPaths';
import CitationPickerWidget from './CitationPicker/CitationPickerWidget';
import RadioListWidgetPaths from './RadioList/RadioListWidgetPaths';
import ResponsiveScriptWidget from './ResponsiveScript/ResponsiveScriptWidget';
import TQAnswerWidget from './TQAnswer/TQAnswerWidget';

const reviewTypeWidget = props => {
  const guideDetails = props.formContext?.guideDetails;
  let guideName = guideDetails?.guideName;
  let performanceArea = guideDetails?.performanceArea;
  return 'Follow-up' === props.formContext?.reviewType &&
    'fotp' === guideDetails?.collectedDataType &&
    'Additional Citation' !== props.formContext?.evidenceSource &&
    !isEmpty(performanceArea) ? (
    <div style={{ padding: '0px' }}>
      <span
        style={{
          color: 'rgba(0, 0, 0, 0.7)',
          textAlign: 'left',
          fontSize: '16px',
          fontFamily: 'PublicSans-Bold',
          marginTop: '15px',
          display: 'block',
        }}
      >
        <b
          style={{
            float: 'left',
            marginRight: '2px',
            fontFamily: 'PublicSans-Bold',
          }}
        >
          {'Guide: '}
        </b>{' '}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(guideName),
          }}
        />
      </span>
      <span
        style={{
          color: 'rgba(0, 0, 0, 0.7)',
          textAlign: 'left',
          fontSize: '16px',
          fontFamily: 'PublicSans-Bold',
          marginTop: '15px',
          display: 'block',
        }}
      >
        <b
          style={{
            float: 'left',
            marginRight: '2px',
            fontFamily: 'PublicSans-Bold',
          }}
        >
          {'Performance Area: '}
        </b>{' '}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(performanceArea),
          }}
        />
      </span>
    </div>
  ) : (
    <></>
  );
};
const evidenceSourceWidget = props => {
  const guideDetails = props.formContext?.guideDetails;
  let performanceArea = props.schema.performanceArea;
  return 'Follow-up' === props.formContext?.reviewType &&
    'fotp' !== guideDetails?.collectedDataType &&
    !isEmpty(performanceArea) ? (
    <div>
      <span
        style={{
          color: 'rgba(0, 0, 0, 0.7)',
          textAlign: 'left',
          fontSize: '16px',
          fontFamily: 'PublicSans-Bold',
        }}
      >
        <b
          style={{
            float: 'left',
            marginRight: '2px',
            fontFamily: 'PublicSans-Bold',
          }}
        >
          {'Performance Area: '}
        </b>{' '}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(performanceArea),
          }}
        />
      </span>
    </div>
  ) : (
    <></>
  );
};
const performanceAreaWidget = props => {
  const guideDetails = props.formContext?.guideDetails;
  let evidenceSource = props.schema.evidenceSource;
  return 'Follow-up' === props.formContext?.reviewType &&
    'fotp' !== guideDetails?.collectedDataType &&
    !isEmpty(evidenceSource) ? (
    <div>
      <span
        style={{
          color: 'rgba(0, 0, 0, 0.7)',
          textAlign: 'left',
          fontSize: '16px',
          fontFamily: 'PublicSans-Bold',
        }}
      >
        <b
          style={{
            float: 'left',
            marginRight: '2px',
            fontFamily: 'PublicSans-Bold',
          }}
        >
          {'Guide: '}
        </b>{' '}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(evidenceSource),
          }}
        />
      </span>
    </div>
  ) : (
    <></>
  );
};
const performanceMeasureWidget = props => {
  return <></>;
};
const tagsWidget = props => {
  return <></>;
};
const orderWidget = props => {
  return <></>;
};
const fiscalYearWidget = props => {
  return <></>;
};
const contentAreaWidget = props => {
  return <></>;
};
const programTypeWidget = props => {
  return <></>;
};
const programOptionWidget = props => {
  return <></>;
};
const findingCategoryWidget = props => {
  return <></>;
};
export function labelWidget(props) {
  let descriptionValue = props.schema.description || '';
  if (descriptionValue && descriptionValue.length > 0) {
    descriptionValue = descriptionValue.replace(
      /<strong>/gi,
      '<b style="color: rgb(0, 0, 0, 1);">'
    );
    descriptionValue = descriptionValue.replace(/<\/strong>/gi, '</b>');
  }
  return (
    <>
      <p
        className="tq-answer-description"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(descriptionValue),
        }}
      />
    </>
  );
}

const accordionTitleWidget = props => {
  return <></>;
};

const scriptWidget = props => {
  let value = props.schema.value;
  if (value && value.length > 0) {
    value = value.replace(/<strong>/gi, '<b style="color: rgb(0, 0, 0, 1);">');
    value = value.replace(/<\/strong>/gi, '</b>');
  }
  var labelClass = 'script ' + props.schema.className;
  return !isEmpty(props.schema.value) ? (
    <div className={labelClass}>
      <span style={{ color: 'rgba(0, 0, 0, 0.7)', textAlign: 'left' }}>
        <b style={{ color: 'rgba(0, 0, 0, 1)' }}>{'Reviewer Script: '}</b>{' '}
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
      </span>
    </div>
  ) : (
    <></>
  );
};

const referenceNotesWidget = props => {
  if (props.schema.display === 'none') {
    return <></>;
  }
  let value = props.schema.value;
  if (value && value.length > 0) {
    value = value.replace(/<strong>/gi, '<b style="color: rgb(0, 0, 0, 1);">');
    value = value.replace(/<\/strong>/gi, '</b>');
  }
  return !isEmpty(value) ? (
    <div>
      <span style={{ color: 'rgba(0, 0, 0, 0.7)', textAlign: 'left' }}>
        <b
          style={{
            color: 'rgba(0, 0, 0, 1)',
            float: 'left',
            marginRight: '2px',
          }}
        >
          {'Reference Notes: '}
        </b>{' '}
        <span
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
        ></span>
      </span>
    </div>
  ) : (
    <></>
  );
};
const guidanceWidget = props => {
  if (props.schema.display === 'none') {
    return <></>;
  }
  let value = props.schema.value;
  var labelClass = 'guidance ' + props.schema.className;
  return !isEmpty(value) && value != undefined ? (
    <div className={labelClass}>
      <AmsPopup
        label="info circle"
        dangerouslySetInnerHTML={true}
        content={value}
        type="Icon"
      />
    </div>
  ) : (
    <div></div>
  );
};
const citationsWidget = props => {
  if (props.schema.display === 'none') {
    return <></>;
  }
  var labelClass = 'citations ' + props.schema.className;
  return props.schema.value?.length > 0 ? (
    <div className={labelClass}>
      {props.schema.value?.map(c => (
        <a
          className="citation-url"
          target="_blank"
          href={'/review/citations/definition?citation=' + c}
        >
          {c}
        </a>
      ))}
    </div>
  ) : (
    <></>
  );
};

export const customWidgets = {
  review_type: reviewTypeWidget,
  performance_area: performanceAreaWidget,
  performance_measure: performanceMeasureWidget,
  tags: tagsWidget,
  order: orderWidget,
  content_area: contentAreaWidget,
  program_type: programTypeWidget,
  program_option: programOptionWidget,
  finding_category: findingCategoryWidget,
  evidence_source: evidenceSourceWidget,
  tq_answer: TQAnswerWidget,
  CAPA: CAPAWidget,
  PercentageCalculation: PercentageCalculationWidget,
  GrantsList: GrantsListWidget,
  CitationPicker: CitationPickerWidget,
  checkboxes: CheckboxListWidgetPaths,
  eas_radio_list: RadioListWidgetPaths,
  citations: citationsWidget,
  reference_notes: referenceNotesWidget,
  script: scriptWidget,
  guidance: guidanceWidget,
  label_text: labelWidget,
  responsive_script: ResponsiveScriptWidget,
  accordion_title: accordionTitleWidget,
  fiscal_year: fiscalYearWidget,
};
